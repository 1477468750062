import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './i18n'; // Import the i18n configuration
import './App.css'; // Import CSS for styling modal
import { Asset } from './types';


interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  barData: any;
  asset: Asset | null;  // Add the asset as a prop
}

const ModalTalentScore: React.FC<ModalProps> = ({ isOpen, onClose, barData, asset }) => {
  const { t } = useTranslation();
  useEffect(() => {
    // Close the modal when clicking outside (desktop only)
    const handleClickOutside = (event: MouseEvent) => {
      if (isOpen && event.target instanceof HTMLElement && event.target.classList.contains('modal-overlay')) {
        onClose();
      }
    };
    if (window.innerWidth > 768) {
      window.addEventListener('click', handleClickOutside);
    }
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen || !barData) return null;

  const getBackgroundColor = (level:string) => {
    switch (level) {
      case 'Underperforming':
        return 'rgb(242, 36, 36)';
      case 'Lagging':
        return 'rgb(255, 112, 8)';
      case 'Active':
        return 'rgb(255, 234, 8)';
      case 'Engaging':
        return 'rgb(8, 238, 255)';
      case 'Outstanding':
        return 'rgb(54, 242, 4)';
      default:
        return '#FFFFFF'; // Default color if no matching level is found
    }
  };

  const getColor = (level:string) => {
    switch (level) {
      case 'Underperforming':
        return '#380702';
      case 'Lagging':
        return '#261102';
      case 'Active':
        return '#5e5706';
      case 'Engaging':
        return '#11084a';
      case 'Outstanding':
        return '#0d3902';
      default:
        return '#FFFFFF'; // Default color if no matching level is found
    }
  };

  const currentHostname = window.location.href;
    const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
    const linkUrl = currentHostname.includes(`${stagingKey}`)
      ? `https://${stagingKey}.peaxel.me`
      : 'https://peaxel.me';


  return (
    
   <div className="score-modal-overlay">
      <div className="score-modal-content">
        
        
        <div className='score-modal-close'>
        <div className='score-header'>
        <img className="turnover-card2" src={asset?.talentCard?.profile_new_url} alt="talent" />
        {t('scoreInfo')}</div>
        <button 
              className="close-button-modal1" 
              onClick={() => {onClose()}}>
             <i className="fa-regular fa-circle-xmark"></i>
       </button>
       </div>
        


       <div className='score-modal-info'>

       <div className="modal-div-top">
        <span className="score-span-intro">{t('week')}&nbsp;{barData.datum.x}</span>
        <span className='score-span-intro-right'>
        <span className="score-span">{t('Score_')}&nbsp;{barData.datum.y}&nbsp;{t('Points')}</span>
        <span className="score_beside">
        <span style={{ background: getBackgroundColor(barData.datum.level_score_last_week), 
          color: getColor(barData.datum.level_score_last_week),
          padding: '2px 4px',
          borderRadius: 5,
          fontSize: '40%',
  textTransform: 'uppercase',
  fontWeight: 900,
  letterSpacing: -0.4 }}>
      {t(barData.datum.level_score_last_week)}
    </span>
    <span className="score_beside_logo">{t('Provided by')}&nbsp;<a href="https://nodatanobusiness.com/" target="_blank" rel="noopener noreferrer"><img className="logoNoDataNoBusiness"src={`${linkUrl}/wp-content/uploads/2024/10/80528818-f3eb-4d61-9d88-050cc36ef471-62fbd91f-4972-4dc6-ac95-6d7619774c4b.png`} alt="NoDataNoBusiness" /></a></span>
    </span>
    
        </span>

       

        <span className="measure-date-span">{t('dateScrapping')}: <span>{barData.datum.date_scrapping}</span></span>
        </div>

        <div className="modal-div-summary">
        <span>{t('totalFollowers')}: <strong>{barData.datum.followers_number_total}</strong> <span style={{color: 'gray', fontWeight: '100'}}> | {t('previousWeek')}: {barData.datum.previous_followers_count} </span> </span>
        <span>{t('postsLastWeek')}: <strong>{barData.datum.posts_number_last_week}</strong></span>
        <span>{t('likesLastWeek')}: <strong>{barData.datum.likes_number_last_week}</strong></span>
        <span>{t('commentsLastWeek')}: <strong>{barData.datum.comments_number_last_week}</strong></span>
        </div>


        <div className="modal-div">
        <span className="score-modal-title">{t('engagementRateLastWeek')}
        <span style={{ background: getBackgroundColor(barData.datum.level_engagement_rate_last_week), color: getColor(barData.datum.level_engagement_rate_last_week),padding: '2px 4px',
  borderRadius: 5,
  fontSize: '40%',
  textTransform: 'uppercase',
  fontWeight: 900,
  letterSpacing: -0.4 }}>
    {t(barData.datum.level_engagement_rate_last_week)}
  </span>
        </span>

        

        <div className="info-tab-inside" style={{opacity: '1'}}>
        
  <div className="formula-container">
  <span className="calculusInfo">
  <span className="numerator">
  ( {t('likesLastWeek')} + {t('commentsLastWeek')} ) × 10,000
  </span>
  <span className="denominator">{t('totalFollowers')}</span>
  </span>
  </div>

  <div className="formula-container">
  <span className="calculusInfo2">
  <span className="numerator3">
  ( {barData.datum.likes_number_last_week} + {barData.datum.comments_number_last_week} ) × 10,000
  </span>
  <span className="denominator">{barData.datum.followers_number_total}</span>
  </span>
  </div>
        
        <span className="score-span">{barData.datum.engagement_rate_last_week}&nbsp;{t('Points')}</span>
       
        </div>
        </div>



        <div className="modal-div">
        <span className="score-modal-title">{t('engagementPerPost')}
        <span style={{ background: getBackgroundColor(barData.datum.level_engagement_per_post_last_week), color: getColor(barData.datum.level_engagement_per_post_last_week),padding: '2px 4px',
  borderRadius: 5,
  fontSize: '40%',
  textTransform: 'uppercase',
  fontWeight: 900,
  letterSpacing: -0.4 }}>
    {t(barData.datum.level_engagement_per_post_last_week)}
  </span>
        </span>
        
        <div className="info-tab-inside" style={{opacity: '1'}}>
        
        <div className="formula-container">
        <span className="calculusInfo">
        <span className="numerator">
        ( {t('likesLastWeek')} + {t('commentsLastWeek')} )
        </span>
        <span className="denominator">{t('postsLastWeek')}</span>
        </span>
        </div>

        <div className="formula-container">
        <span className="calculusInfo2">
        <span className="numerator3">
        ( {barData.datum.likes_number_last_week} + {barData.datum.comments_number_last_week} )
        </span>
        <span className="denominator">{barData.datum.posts_number_last_week}</span>
        </span>
        </div>
        
        
        <span className="score-span">{barData.datum.engagement_per_post_last_week}&nbsp;{t('Points')}</span>
        
        </div>
        </div>



        <div className="modal-div">
        <span className="score-modal-title">{t('contentEngagementQuality')}
        <span style={{ background: getBackgroundColor(barData.datum.level_content_engagement_quality_last_week), color: getColor(barData.datum.level_content_engagement_quality_last_week),padding: '2px 4px',
  borderRadius: 5,
  fontSize: '40%',
  textTransform: 'uppercase',
  fontWeight: 900,
  letterSpacing: -0.4 }}>
    {t(barData.datum.level_content_engagement_quality_last_week)}
  </span>
        </span>
        
        <div className="info-tab-inside" style={{opacity: '1'}}>
        
        <div className="formula-container">
        <span className="calculusInfo">
        <span className="numerator">
        ( {t('commentsLastWeek')} ) × 10,000
        </span>
        <span className="denominator">( {t('likesLastWeek')} + {t('commentsLastWeek')} )</span>
        </span>
        </div>

        <div className="formula-container">
        <span className="calculusInfo2">
        <span className="numerator3">
        ( {barData.datum.comments_number_last_week} ) × 10,000
        </span>
        <span className="denominator">( {barData.datum.likes_number_last_week} + {barData.datum.comments_number_last_week})</span>
        </span>
        </div>
        
        <span className="score-span">{barData.datum.content_engagement_quality_last_week}&nbsp;{t('Points')}</span>
        
        </div>
        </div>





        <div className="modal-div">
        <span className="score-modal-title">{t('interactionRateLastWeek')}
        <span style={{ background: getBackgroundColor(barData.datum.level_interaction_rate_last_week), color: getColor(barData.datum.level_interaction_rate_last_week),padding: '2px 4px',
  borderRadius: 5,
  fontSize: '40%',
  textTransform: 'uppercase',
  fontWeight: 900,
  letterSpacing: -0.4 }}>
    {t(barData.datum.level_interaction_rate_last_week)}
  </span>
        </span>
        
        <div className="info-tab-inside" style={{opacity: '1'}}>
        
        <div className="formula-container">
        <span className="calculusInfo">
        <span className="numerator">
        ( {t('commentsLastWeek')} ) × 10,000
        </span>
        <span className="denominator">( {t('totalFollowers')} )</span>
        </span>
        </div>

        <div className="formula-container">
        <span className="calculusInfo2">
        <span className="numerator3">
        ( {barData.datum.comments_number_last_week} ) × 10,000
        </span>
        <span className="denominator">( {barData.datum.followers_number_total} )</span>
        </span>
        </div>
        
        <span className="score-span">{barData.datum.interaction_rate_last_week}&nbsp;{t('Points')}</span>
        
        </div>
        </div>






        <div className="modal-div">
        <span className="score-modal-title">{t('followersGrowth')}
        <span style={{ background: getBackgroundColor(barData.datum.level_followers_growth_last_week), color: getColor(barData.datum.level_followers_growth_last_week),padding: '2px 4px',
  borderRadius: 5,
  fontSize: '40%',
  textTransform: 'uppercase',
  fontWeight: 900,
  letterSpacing: -0.4 }}>
    {t(barData.datum.level_followers_growth_last_week)}
  </span>
        </span>
        
        <div className="info-tab-inside" style={{opacity: '1'}}>
        
        <div className="formula-container">
        <span className="calculusInfo">
        <span className="numerator">
        ( {t('followersCount')} - {t('previousFollowersCount')} ) × 100
        </span>
        <span className="denominator">( {t('previousFollowersCount')} )</span>
        </span>
        </div>

        <div className="formula-container">
        <span className="calculusInfo2">
        <span className="numerator3">
        ( {barData.datum.followers_number_total} - {barData.datum.previous_followers_count} ) × 10,000
        </span>
        <span className="denominator">( {barData.datum.previous_followers_count} )</span>
        </span>
        </div>
        
        <span className="score-span">{barData.datum.followers_growth_last_week}&nbsp;{t('Points')}</span>
      
        



        </div>
        </div>






        <div className="modal-div">
        <span className="score-modal-title">{t('weeklyScore')}
        <span style={{ background: getBackgroundColor(barData.datum.level_score_last_week), color: getColor(barData.datum.level_score_last_week),padding: '2px 4px',
  borderRadius: 5,
  fontSize: '40%',
  textTransform: 'uppercase',
  fontWeight: 900,
  letterSpacing: -0.4 }}>
    {t(barData.datum.level_score_last_week)}
  </span>
        </span>
       
         <div className="info-tab-inside" style={{opacity: '1'}}>
        
        <div className="formula-container">
        <span className="calculusInfo">
        <span className="numerator2">
        ( 0.45 × {t('engagementRateLastWeek')} + 0.3 × {t('engagementPerPost')} + 0.03 × {t('contentEngagementQuality')} + 0.11 × {t('interactionRateLastWeek')} + 0.11 × {t('followersGrowth')} ) × 100
        </span>
        </span>
        </div>

        <div className="formula-container">
        <span className="calculusInfo2">
        <span className="numerator2">
        ( 0.45 × {barData.datum.engagement_rate_last_week} + 0.3 × {barData.datum.engagement_per_post_last_week} + 0.03 × {barData.datum.content_engagement_quality_last_week} + 0.11 × {barData.datum.interaction_rate_last_week} + 0.11 × {barData.datum.followers_growth_last_week} ) × 100
        </span>
        </span>
        </div>
        
        
        <span className="score-span">{barData.datum.y}&nbsp;{t('Points')}</span>
        
        </div>
        </div>



        </div>

      </div>
      </div>
  );
};

export default ModalTalentScore;
